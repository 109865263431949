import { computed, ref } from 'vue';
import { differenceInYears, format } from 'date-fns';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
import { mapPersonalDetails } from '@/utils/formatters';
import { isEmpty } from 'lodash';

export function usePersonalDetailsNext () {
  const sdk = useSdk();

  async function getItem (id) {
    const res = await sdk?.get('personal-details', id);
    return mapPersonalDetails(res);
  }

  async function updateItem (id, data) {
    if (data.$unset) {
      await sdk?.update('personal-details', id, { $unset: data.$unset });
    }
    return sdk?.update('personal-details', id, data);
  }

  async function checkWebsiteUniqueness (opts) {
    const id = opts?.id;
    const doc_website = opts?.doc_website;

    const query = {
      $and: [
        { id: { $ne: id } },
        { doc_website },
      ],
    };

    const result = await sdk.list('personal-details', query);
    return result.total === 0;
  }

  return {
    getItem,
    updateItem,
    checkWebsiteUniqueness,
  };
}

export function usePersonalDetails () {
  try {
    const sdk = useSdk();
    const currentUser = ref(null);
    sdk?.currentUser().then((user) => {
      currentUser.value = user;
    });
    const uid = computed(() => currentUser.value?.uid);

    const personalDetails = ref({});
    const familyMembers = ref(null);
    const { activeFacility } = useFacilities();

    const age = computed(() => dateOfBirth.value && differenceInYears(new Date(), new Date(dateOfBirth.value)));
    const dateOfBirth = computed(() => personalDetails.value?.dateOfBirth);
    const dateOfBirthFormatted = computed(() => dateOfBirth.value && format(new Date(dateOfBirth.value), 'dd/MM/yyyy'));
    const email = computed(() => personalDetails.value?.email);
    const firstName = computed(() => name.value?.firstName);
    const lastName = computed(() => name.value?.lastName);
    const middleName = computed(() => name.value?.middleName);
    const name = computed(() => personalDetails.value?.name);
    const picURL = computed(() => personalDetails.value?.picURL);
    const sex = computed(() => personalDetails.value?.sex);
    const bloodType = computed(() => personalDetails.value?.bloodType);
    const address = computed(() => personalDetails.value?.address);
    const mobileNo = computed(() => personalDetails.value?.mobileNo);
    const homeNo = computed(() => personalDetails.value?.workNo);
    const workNo = computed(() => personalDetails.value?.homeNo);

    const init = async () => {
      const currentUser = await sdk?.currentUser();
      personalDetails.value = await sdk?.get('personal-details', currentUser.uid);
    };

    const initWithId = async (id) => {
      personalDetails.value = await sdk?.get('personal-details', id);
    };

    const create = async (data) => {
      const payload = {
        ...data,
      };

      payload.facility = activeFacility.value?.id;

      const result = await sdk?.create('personal-details', payload);
      personalDetails.value = result;

      return result;
    };

    const update = async (data) => {
      if (!isEmpty(data.$unset)) {
        await sdk?.update('personal-details', uid.value, { $unset: data.$unset });
        delete data.$unset;
      }
      const result = await sdk?.update('personal-details', uid.value, data);
      personalDetails.value = result;
      return result;
    };

    const findApproximateFamilyMembers = async (id, opts) => {
      const data = {
        relatedPerson: id,
        ...opts,
      };
      const res = await sdk?.list('personal-details', data);
      familyMembers.value = res.data;
      return res.data;
    };

    // this other function was created becasue the uid.value of the patient being edited should not be equal to the current user
    const updateWithPatientId = async (data, id) => {
      if (data.$unset) {
        await sdk?.update('personal-details', id, { $unset: data.$unset });
        delete data.$unset;
      }
      personalDetails.value = await sdk?.update('personal-details', id, data);
    };

    return {
      personalDetails,
      familyMembers,
      //
      age,
      dateOfBirth,
      dateOfBirthFormatted,
      email,
      firstName,
      lastName,
      middleName,
      name,
      picURL,
      sex,
      bloodType,
      address,
      mobileNo,
      homeNo,
      workNo,
      //
      init,
      create,
      update,
      updateWithPatientId,
      initWithId,
      findApproximateFamilyMembers,
    };
  } catch (e) {
    console.error(e);
  }
}
